import { Component, OnInit } from '@angular/core';
import { StaticService } from 'src/app/services/static.service';
import { HttpService } from 'src/app/services/http.service';
import { Router, ActivatedRoute } from '@angular/router';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../../../assets/css/login-styles.css', './reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  newPasswordValue: string = "";
  confirmPasswordValue: string = "";
  loginForm;
  encMsg: string;
  errorMsg: string;

  isAnimo: string = "animo-default";
  isLoader: string = "no-loader";
  token: string;

  constructor(private staticService: StaticService,
    private httpService: HttpService, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
    let tokenNo = this.route.snapshot.paramMap.get('token');

    console.log(tokenNo);

    this.token = tokenNo;
    if (this.token) {
      this.checkIsValidUrlData(this.token);
    }

  }

  passwordComparison = () => {
    return this.newPasswordValue;
  };
  checkComparison() {
    return true;
  }

  upperCaseChrPattern: any = /[A-Z]+/g
  lowerCaseCharPattern = /[a-z]+/g
  numberCharPattern = /[0-9]+/g
  specialCharPattern = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/

  checkIsValidUrlData(token: string) {
    if (token != "") {
      this.httpService.get("ResetLink/IsValid", { token: token }).subscribe(
        d => {
          let result = JSON.parse(JSON.stringify(d));
          console.log(result);
          if (result == 200) {

            // valid url


          }
          else {
            this.router.navigate(['/forgetpassword']);
          }
        },
        e => {
          notify("Error! Server Connection Refused.", "error", 2500);
        }
      );
    } else {
      this.router.navigate(['/forgetpassword']);
    }

  }

  submitReq(e) {
    console.log(e.validationGroup.validators);

    let clientValidationCheck: boolean = false;
    if (e.validationGroup.validators) {
      if (
        e.validationGroup.validators[0]._options.isValid &&
        e.validationGroup.validators[1]._options.isValid) {
        clientValidationCheck = true;
      } else {
        clientValidationCheck = false;
      }
    }
    
    console.log(clientValidationCheck);

    if (clientValidationCheck) {

      let go = 1;
      if (this.newPasswordValue == "") {
        this.errorMsg = "Please enter a new password."; //
        this.clearWarning();
        go = 0;
      }

      else if (this.confirmPasswordValue == "") {
        this.errorMsg = "Please enter confirm password."; //
        this.clearWarning();
        go = 0;
      }

      else if (this.newPasswordValue != this.confirmPasswordValue) {
        this.errorMsg = "Password and Confirm Password do not match."; //
        this.clearWarning();
        go = 0;
      }

      else if (this.newPasswordValue != "" && this.confirmPasswordValue != "") {
        go = 1;
      }

      else {
        go = 0;
      }

      if (go > 0 && this.token != "") {
        this.isLoader = "yes-loader";
        this.httpService.post("ResetLink/ResetPassword", { token: this.token, passKey: this.confirmPasswordValue }).subscribe(d => {
          this.isLoader = "no-loader";
          var val = JSON.parse(JSON.stringify(d));
          console.log(val);
          if (val == 200) {

            alert("Password reset successfully done.");
            this.router.navigate(['/login']);
          }
          else if (val == 406) {
            this.errorMsg = "Invalid Request!"; //
            this.clearWarning();
          }
          else {
            this.errorMsg = "Unable to reset your password."; //
            this.clearWarning();
          }
        }, e => {
          this.isLoader = "no-loader";
          if (e.status == 400 && e.error != null) {
            this.errorMsg = e.error.Message; //
            this.clearWarning();
          } else {
            notify("Error! Server Connection Refused.", "error", 2500);
          }
        });
      } else {
        this.errorMsg = "Please enter all required fields."; //
        this.clearWarning();
      }

    }

  }

  clearWarning() {
    setTimeout(() => {
      this.errorMsg = "";
    }, 5000);
  }

}
