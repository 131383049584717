import { Component, ViewChild } from '@angular/core';
import { Router } from "@angular/router"
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { StaticService } from "../../services/static.service"
import { HttpService } from "../../services/http.service";
import { Authdata } from '../../classes/authdata.class';
//import { DxButtonComponent, DxDataGridComponent } from 'devextreme-angular';
import notify from "devextreme/ui/notify";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../../../assets/css/login-styles.css', './login.component.css']
})

export class LoginComponent {

  registerUrl: string;

  constructor(private staticService: StaticService,
    private httpService: HttpService, private router: Router) {
      this.getLocation();
    this.registerUrl = staticService.getPubUiUrl()+"register";

    this.passwordMode = 'password';
    this.passwordButton = {
      icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVRYw+2YP0tcQRTFz65xFVJZpBBS2O2qVSrRUkwqYfUDpBbWQu3ELt/HLRQ/Q8RCGxVJrRDEwj9sTATxZ/Hugo4zL/NmV1xhD9xi59177pl9986fVwLUSyi/tYC+oL6gbuNDYtyUpLqkaUmfJY3a+G9JZ5J2JW1J2ivMDBSxeWCfeBxYTHSOWMcRYLOAEBebxtEVQWPASQdi2jgxro4E1YDTQIJjYM18hszGbew4EHNq/kmCvgDnHtI7YBko58SWgSXg1hN/btyFBM0AlwExczG1YDZrMS4uLUeUoDmgFfjLGwXEtG05wNXyTc4NXgzMCOAIGHD8q0ATuDZrempkwGJ9+AfUQ4K+A/eEseqZ/UbgdUw4fqs5vPeW+5mgBvBAPkLd8cPju+341P7D/WAaJGCdOFQI14kr6o/zvBKZYz11L5Okv5KGA89Kzu9K0b0s5ZXt5PjuOL6TRV5ZalFP4F+rrnhZ1Cs5vN6ijmn7Q162/ThZq9+YNW3MbfvDAOed5cxdGL+RFaUPKQtjI8DVAr66/u9i6+jJzTXm+HFEVqxVYBD4SNZNKzk109HxoycPaG0bIeugVDTp4hH2qdXJDu6xOAAWiuQoQdLHhvY1aEZSVdInG7+Q9EvSz9RrUKqgV0PP3Vz7gvqCOsUj+CxC9LB1Dc8AAAASdEVYdEVYSUY6T3JpZW50YXRpb24AMYRY7O8AAAAASUVORK5CYII=",
      type: "passview",
      onClick: () => {
        this.passwordMode = this.passwordMode === "text" ? "password" : "text";
      }
    };
  }

  lat : number;
  long : number;
  magMsg: string;
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position)=>{
        console.log(position);
        this.lat = position && position.coords && position.coords.altitude ? position.coords.altitude : 0;
        this.long = position && position.coords && position.coords.longitude ? position.coords.longitude : 0;
      });
    } else { 
      this.magMsg = "Geolocation is not supported by this browser.";
    }
  }

  companyName: string;
  logUserId: string = "";
  loginForm;
  encMsg: string;
  errorMsg: string;

  buttonText = " Login . .";
  passwordMode: string;
  passwordButton: any;

  isAnimo: string = "animo-default";
  isLoader: string = "no-loader";

  ngOnInit() {

    this.companyName = this.staticService.companyName;

    this.loginForm = new FormGroup({
      UserId: new FormControl("", Validators.compose([
        Validators.required
      ]))
      , Password: new FormControl("", Validators.compose([
        Validators.required
      ]))
    });

    new Authdata().clearData();
  }

  // keyColumnNameGridData: any = "Id";
  popupResponsibilityVisible: boolean = false;
  responsibilityList: any[] = [];
  setResponsibility(selectedResponsibities) {
    //
    console.log(selectedResponsibities);

    // debugger;
    if (selectedResponsibities && selectedResponsibities.length > 0) {
      sessionStorage.setItem(this.staticService.selectedResponsibilityKey, JSON.stringify(selectedResponsibities[0]));
      //  debugger;
      this.router.navigate(["dashboard"]);
    }
  }

  // getVal(a) {
  //   ////console.log(a);
  // }

  forgotPass() {
    this.router.navigate(["forgetpassword"]);
  }

  clearWarning() {
    setTimeout(() => {
      this.errorMsg = "";
    }, 5000);
  }

  login(val) {
    sessionStorage.clear();
    // this.staticService.showProgressbar();
    this.isAnimo = "animo";
    this.isLoader = "yes-loader";
    // this.httpService.get("testreport").subscribe(d=>{
    //   var result = JSON.parse(JSON.stringify(d));
    //   console.log(result);
    //   printJS({printable: result, type: 'pdf', base64: true});
    //   console.log('print pdf');
    // })
    this.httpService.findKey(val.UserId, val.Password)
      .subscribe(
        (d) => {
          // this.staticService.hideProgressbar();
          var json = JSON.parse(JSON.stringify(d));
          console.log(json);
          var authData = new Authdata();
          console.log(authData);
          authData.setData(JSON.stringify(json));
          // debugger;
          this.responsibilityList = JSON.parse(json.Responsibilities);
          if (this.responsibilityList && this.responsibilityList.length == 1) {
            this.setResponsibility(this.responsibilityList);
          }
          else if (this.responsibilityList && this.responsibilityList.length > 1) {
            this.popupResponsibilityVisible = true;
          }
          else {
            notify("You are not authorized.", "error", 3000);
          }

          console.log(this.responsibilityList);

          //this.router.navigate(["app"]);
        }
        , (error) => {
          console.log(error.error.error);
          console.log(error.error.error_description);
          // this.staticService.hideProgressbar();
          this.isAnimo = "animo-default";
          this.isLoader = "no-loader";
          this.errorMsg = error.error.error_description;//"Invalid user name or password";
          this.clearWarning();
        }
        , () => {
          // this.staticService.hideProgressbar();
          this.isAnimo = "animo-default";
          this.isLoader = "no-loader";
        }
      );
  }

  // @ViewChild('responsibilityGrid') resItemGrid: DxDataGridComponent;
  // selectedGridRow: any = [];
  // gridKeyDown(e) {
  //   if (e.event.keyCode == 13) {

  //     //  console.log('grid enter key');
  //     //  console.log(e);
  //     //  console.log(this.itemSearchGrid.focusedRowKey);
  //     this.resItemGrid.selectedRowKeys = this.resItemGrid.focusedRowKey;
  //     this.selectedGridRow = this.resItemGrid.focusedRowKey;
  //     // this.itemDropDown.instance.close();
  //     // this.onProductSelected.emit(this.gridProduct);
  //   }
  // }
}