import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule, NgModel } from "@angular/forms";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Router } from "@angular/router";

import {TabModule} from 'angular-tabs-component';

import { AppComponent } from './components/app.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ApplicationComponent } from './components/application/application.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { ForgetpassComponent } from './components/forgetpass/forgetpass.component';

import { Authdata } from './classes/authdata.class';

import { DxFormModule, DxMenuModule, DxTextBoxModule, DxTextAreaModule, DxValidatorModule, DxButtonModule, DxLoadPanelModule, DxChartModule, DxTileViewModule, DxListModule, DxFileUploaderModule, DxToolbarModule, DxSelectBoxModule, DxDataGridModule, DxPopupModule, DxTagBoxModule, DxLookupModule, DxDrawerModule, DxRadioGroupModule, DxTooltipModule, DxTemplateModule, DxContextMenuModule, DxAccordionModule, DxNumberBoxModule, DxDropDownBoxModule, DxTabPanelModule, DxScrollViewModule } from 'devextreme-angular';

import { MenuComponent } from './components/menu/menu.component';
import { TopmenuComponent } from './components/topmenu/topmenu.component';
import { HttpClientModule } from '@angular/common/http';
import { StaticService } from './services/static.service';
import { HttpService } from './services/http.service';
import { StccommonModule } from './stccommon/stccommon.module';
import { ClientFeedbackComponent } from './components/client-feedback/client-feedback.component';
import { SwCommonModule } from './components/sw-common/sw-common.module';
import { FeedbackThankYouComponent } from './components/feedback-thank-you/feedback-thank-you.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    ApplicationComponent,
    NotfoundComponent,
    ForgetpassComponent,
    MenuComponent,
    TopmenuComponent,
    ClientFeedbackComponent,
    FeedbackThankYouComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule
    , BrowserAnimationsModule
    , FormsModule
    , ReactiveFormsModule
    , DxFormModule
    , DxTextBoxModule
    , DxTextAreaModule
    , DxMenuModule
    , DxValidatorModule
    , DxButtonModule
    , DxLoadPanelModule
    , DxChartModule
    , HttpClientModule
    , DxTileViewModule
    , DxListModule
    , DxFileUploaderModule
    , DxToolbarModule
    , DxSelectBoxModule
    , DxDataGridModule
    , DxPopupModule
    , DxLookupModule
    , DxTagBoxModule
    , DxAccordionModule
    , DxDrawerModule
    , DxRadioGroupModule
    , DxTooltipModule
    , DxTemplateModule
    , DxContextMenuModule
    , DxNumberBoxModule
    , DxDropDownBoxModule
    , DxTabPanelModule
    , DxScrollViewModule

    , TabModule

    , StccommonModule
    , SwCommonModule
    , RouterModule.forRoot([
      {
        path: "login"
        , component: LoginComponent
      },
      {
        path: "forgetpassword"
        , component: ForgetpassComponent
      },
      {
        path: "reset-password/:token"
        , component: ResetPasswordComponent
      },
      {
        path: "reset-password"
        , component: ResetPasswordComponent
      },
      {
        path: "client-feedback/:sCode/:cCode/:mCode"
        , component: ClientFeedbackComponent
      },
      {
        path: "thank-you/:sCode/:cCode/:mCode"
        , component: FeedbackThankYouComponent
      },
      // {
      //   path: "main"
      //   , component: DashboardComponent
      //   , children: [{ path: "app", component: ApplicationComponent }]
      // },
      // {
      //   path: "app"
      //   , component: ApplicationComponent
      //   // , children: [{ path: "app", component: ApplicationComponent }]
      // },
      {
        path: "dashboard"
        , component: ApplicationComponent
        , children: [{ path: "", component: DashboardComponent }]
      },

      {
        path: "admin"
        , component: ApplicationComponent
        , loadChildren: "./modules/admin/admin.module#AdminModule"
      },

      {
        path: "ms"
        , component: ApplicationComponent
        , loadChildren: "./modules/inventory/inventory.module#InventoryModule"
      },

      {
        path: "org"
        , component: ApplicationComponent
        , loadChildren: "./modules/organization/organization.module#OrganizationModule"
      },

      {
        path: "sw"
        , component: ApplicationComponent
        , loadChildren: "./modules/source-window/source-window.module#SourceWindowModule"
      },
      
      {
        path: ""
        , pathMatch: "full"
        , component: LoginComponent
      },
      {
        path: "**"
        , component: NotfoundComponent
      },
    ], { useHash: true })
  ],
  providers: [StaticService, HttpService , Authdata
    // ,httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
  , entryComponents: []
})
export class AppModule { }

