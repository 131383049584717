import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'app-product-select',
  templateUrl: './product-select.component.html',
  styleUrls: ['./product-select.component.css']
})
export class ProductSelectComponent implements OnInit {

  @ViewChild('itemDropDown', {static: false}) itemDropDown;
  @ViewChild('txtSearchText', {static: false}) txtSearchText;
  @ViewChild('itemSearchGrid', {static: false}) itemSearchGrid: DxDataGridComponent;

  @Input() productList: any[];
  @Input() text: any;
  @Input() value: any;
  @Output() onProductSelected = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(data: SimpleChanges) {
    // debugger;
    //console.log('onchanges');
    if (data.productList && data.productList.currentValue) {
      this.productList = data.productList.currentValue;
    }
    if (data.text && data.text.currentValue) {
      this.text = data.text.currentValue;
    }
    if (data.value && data.value.currentValue) {
      this.value = data.value.currentValue;
    }
  }

  //#region item searching

  gridProduct: any = [];
  productFilteredList: any[] = [];
  popupText: string = "";
  // focusedRow:number = 0;
  popupTextKeyDown(e) {
    if (e.event.keyCode == 40) {
      this.itemSearchGrid.instance.focus();
    }
  }
  dropdownOpen(e) {
    //console.log('dropdown open');
    this.productFilteredList = this.productList;
    this.txtSearchText.value = "";
    this.gridProduct = undefined;

    if (this.value && this.value > 0) {
      this.txtSearchText.value = this.text;
      this.gridProduct = this.value;
    }
    // this.itemSearchGrid.instance.focus();
    //console.log('txt search focus');
    //this.itemSearchGrid.focusedRowIndex = 0;
    this.txtSearchText.instance.focus();
    this.findData(this.productList, this.txtSearchText.value, false);
  }
  popupTextEnter(e) {
    // //console.log('enter');

    this.findData(this.productList, this.txtSearchText.value, true);
    // this.focusedRow = 0;
  }
  gridKeyDown(e) {
    if (e.event.keyCode == 13) {

      //  console.log('grid enter key');
      //  console.log(e);
      //  console.log(this.itemSearchGrid.focusedRowKey);
      this.itemSearchGrid.selectedRowKeys = this.itemSearchGrid.focusedRowKey;
      this.gridProduct = this.itemSearchGrid.focusedRowKey;
      this.itemDropDown.instance.close();
      this.onProductSelected.emit(this.gridProduct);
    }
  }
  findData(listData: any[], text: string, focusMoved: boolean) {

    var splittedArray = text.split(" ");
    if (splittedArray && listData) {
      splittedArray.forEach(d => {
        if (listData && listData.length > 0) {

          var tempProduct = [];
          listData.forEach(p => {
            if (p.ProductName.toString().toUpperCase().indexOf(d.toUpperCase()) >= 0) {
              tempProduct.push(p);
            }
          });
          listData = tempProduct;

        }
      });
      this.productFilteredList = listData;

    }
    this.itemSearchGrid.focusedRowIndex = 0;
    if (focusMoved) {
      this.itemSearchGrid.instance.focus();
    }
  }
  onPopupGridToolbarPreparing(e) {
    // e.toolbarOptions.items.unshift(
    //     {
    //     location: 'before',
    //     template: 'popupEditTextTemplate'
    //     }
    // );
  }
  dropdownSelectionChanged(e) {

    this.gridProduct = e.selectedRowKeys[0];
    console.log('emit');
    this.onProductSelected.emit(this.gridProduct);
    this.itemDropDown.instance.close();

  }
  //#endregion

}
