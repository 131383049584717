export class Authdata {
    public authDataKey : string ="LOG_KEY";
    public  setData(data) : boolean {
        try{
            sessionStorage.setItem(this.authDataKey, data);      
            return true;
        }
        catch(e){
            return false;
        }
    }

    public getData()
    {
        var data;
        try{
            // //console.log(sessionStorage.getItem(this.authDataKey));
            data = JSON.parse(sessionStorage.getItem(this.authDataKey));
            // //console.log('authdata '+sessionStorage.getItem(this.authDataKey));
            return data;
        }
        catch(e)
        {
            return null;
        }
    }
    public  clearData() : boolean {
        try{
            sessionStorage.removeItem(this.authDataKey);
            sessionStorage.clear();
            return true;
        }
        catch(e){
            return false;
        }
    }
    
}
