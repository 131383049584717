import { Component, OnInit } from '@angular/core';
// import { matMenu } from "@angular/material";
import { StaticService } from "../../services/static.service";
import { HttpService } from "../../services/http.service";
import { Router } from "@angular/router";
import { Authdata } from "../../classes/authdata.class";
// import { MdListDivider} from "@angular/material"

// import DataSource from 'devextreme/data/data_source';
// import ArrayStore from 'devextreme/data/array_store';
import notify from "devextreme/ui/notify";
import { debug } from 'util';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit {
  screenwidth: any = 500;
  //for product
  //productDataSource: DataSource;
  //currentHotel: Hotel;

  constructor(private staticService: StaticService, private http: HttpService, private router: Router) {

    // this.productDataSource = this.getDataSource();
    // this.currentHotel = this.getFirstHotel();
    this.screenwidth = window.innerWidth;
    if(this.screenwidth<550){
      this.screenwidth = window.innerWidth;
    }else{
      this.screenwidth = 550;
    }
  }


  // listSelectionChanged = (e) => {
  //   this.currentHotel = e.addedItems[0];
  // };


  public userName: string;
  //public userId: string;
  public designation: string;

  public iconName: string = "settings_power";
  public applications: any[] = [];
  public report_menus: any[] = [];
  public profiles: any;

  public logData;
  public responsibilityList: any[];
  public selectedResponsibility;

  public sideNavOpen: boolean = true;
  public sideNavMode: string = "side";

  public rspPopupVisible : boolean = false;

  ngOnInit() {
    this.logData = new Authdata().getData();
    if(this.logData != null){
      this.userName = this.logData.UserName;
      this.designation = this.logData.Designation;
  
      this.selectedResponsibility = JSON.parse(sessionStorage.getItem(this.staticService.selectedResponsibilityKey));
  
      //  debugger;
      // if responsibility already exist
      if (this.selectedResponsibility) {
        //if menu list stored in session then load it otherwise fetch it from api
        // if (sessionStorage.getItem(this.staticService.applicationListName) != null) {
        //   this.applications = JSON.parse(sessionStorage.getItem(this.staticService.applicationListName));
        // }
        // else {
          this.getMenus(this.selectedResponsibility.Id);
        // }
      }
      else {
        // load and check responsibilities from auth data. if single then load menu from api and store it to session storage
        // if multiple then show popup for responsibility selection.
  
        this.responsibilityList = JSON.parse(this.logData.Responsibilities);
        if(this.responsibilityList && this.responsibilityList.length > 0 ){
          // this.showResponsibilityList();
          if(this.responsibilityList.length == 1){
            this.selectedResponsibility = this.responsibilityList[0];
            this.getMenus(this.selectedResponsibility.Id);
          }
          else if(this.responsibilityList.length > 1){
            this.showResponsibilityList();
          }
        }
        else{
          notify("Invalid log in information. Please logout and log in.", "info", 1000);    
        }
      }
    }
  }
  showResponsibilityList(){
    //show popup
    ////console.log(this.responsibilityList);
    this.rspPopupVisible = true;
    

  }
  setResponsibility(e){
     
    var id = e.selectedRowKeys[0];
    var rsp = this.responsibilityList.find(s=>s.Id == id);
    this.selectedResponsibility = rsp;
    this.getMenus(this.selectedResponsibility.Id);
  }
  getMenus(id: number) {
    //this.selectedResponsibility = sessionStorage.getItem(this.staticService.selectedResponsibilityKey);
    var params = { 'rspid': id };
    this.http.get(this.staticService.applicationApi, params).subscribe(
      (data) => {
        var json = JSON.parse(JSON.stringify(data));
        if (data) {
          this.applications = json.MENUS;
          this.report_menus = json.REPORTS;
          // debugger;
          ////console.log(this.applications);
          sessionStorage.setItem(this.staticService.applicationListName, JSON.stringify(this.applications));
          sessionStorage.setItem(this.staticService.reportMenuListName, JSON.stringify(this.report_menus));
        }
        else {
          //alert("Application list failed to load");
          notify("Error! Server Connection Refused.", "error", 1000);
        }
        //this.staticService.hideProgressbar();
      }
      , (error) => {
        //alert("Application list failed to load");
        notify("Error! Server Connection Refused.", "error", 1000);
      }
    );
  }


}