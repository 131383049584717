import { Component, OnInit, ViewChild, EventEmitter, Output, Input, SecurityContext } from '@angular/core';
import { DxFileUploaderComponent } from 'devextreme-angular';
import { StaticService } from 'src/app/services/static.service';
import { HttpService } from 'src/app/services/http.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css']
})
export class FileUploaderComponent implements OnInit {

  // @Input() attachmentTypeId : string;
  @Input() key: string;
  @Input() isReturnUploadedData: boolean = false;
  @Input() isMultiple: boolean = false;
  @Input() uploadMode: string = "useForm";
  @Input() buttonText: string = "Select file";
  @Input() lebelText: string = "";
  @Input() fileId: number = undefined;

  @Input() mimeList: any[];
  @Input() postController: string = 'Attachment';
  @Output() onUploaded = new EventEmitter<any>();

  @ViewChild('fileUploader', { static: false }) uploader: DxFileUploaderComponent;
  // uploadFileType : string ="*.*";

  // @Input() additionalParam : string;
  attachmentTypeId: any;
  uploadedFileName: string = '';
  fileTypeStr: string = '';
  physicalFileName: string = '';

  successMessage: string = '';
  errorMessage: string = '';

  isImage: boolean = false;
  canUpload: boolean = false;
  uploadFileMaxSize: any;

  constructor(private stService: StaticService, private http: HttpService, private sanitizer: DomSanitizer) {
    this.clear();
  }

  ngOnInit() {
  }

  ngOnChanges(data) {
    // console.log(data);
    // if(data.attachmentTypeId && data.attachmentTypeId.currentValue){
    //   this.attachmentTypeId = data.attachmentTypeId.currentValue;
    // }
    if (data.key && data.key.currentValue) {
      this.key = data.key.currentValue;
    }
    if (data.isReturnUploadedData && data.isReturnUploadedData.currentValue) {
      this.isReturnUploadedData = data.isReturnUploadedData.currentValue;
    }
    if (data.mimeList && data.mimeList.currentValue) {
      this.mimeList = data.mimeList.currentValue;

      this.checkImageAndPrepareMimeString();
    }

    if (data.postController && data.postController.currentValue) {
      this.postController = data.postController.currentValue;
    }
    if (data.isMultiple && data.isMultiple.currentValue) {
      this.isMultiple = data.isMultiple.currentValue;
    }
    if (data.uploadMode && data.uploadMode.currentValue) {
      this.uploadMode = data.uploadMode.currentValue;
    }
    if (data.buttonText && data.buttonText.currentValue) {
      this.buttonText = data.buttonText.currentValue;
    }
    if (data.lebelText && data.lebelText.currentValue) {
      this.lebelText = data.lebelText.currentValue;
    }
    if (data.fileId && data.fileId.currentValue) {
      this.fileId = data.fileId.currentValue;
    }
  }
  checkImageAndPrepareMimeString() {
    var count = 0;
    this.fileTypeStr = "";
    console.log(this.mimeList);
    this.attachmentTypeId = this.mimeList && this.mimeList.length > 0 ? this.mimeList[0].Id : 0;
    this.uploadFileMaxSize = this.mimeList && this.mimeList.length > 0 ? this.mimeList[0].MaxFileSize : 0;
    if (this.mimeList && this.mimeList.length > 0) {
      this.mimeList.forEach(d => {
        //debugger;
        if (count <= 0) {
          this.fileTypeStr += ",";
        }
        this.fileTypeStr += d.FileFilter;
      })
    }

    if (this.fileTypeStr.length > 0) {
      if (this.fileTypeStr.toUpperCase().indexOf('IMAGE') >= 0) {
        this.isImage = true;
      }
      else {
        this.isImage = false;
      }
    }
    else {
      this.fileTypeStr = "*.*";
    }
  }
  clear() {
    if (this.uploader)
      this.uploader.instance.reset();
    this.uploadedFileName = '';
    this.fileTypeStr = '';
    this.physicalFileName = '';
    this.canUpload = false;
    this.successMessage = '';
    this.errorMessage = '';
    this.checkImageAndPrepareMimeString();
  }

  attachmentChanged(e) {
    this.canUpload = false;
    if (e.value && e.value.length > 0) {
      // console.log(e);

      if (this.isMultiple) {
        // for multiple upload
        var files = e.value;
        this.varifyFilesType(files);
      } else {
        // for single upload
        var file = e.value[0];
        this.varifyFileType(file);
      }

    }
  }

  byte64ToHex(str: string): string {
    // console.log(str);
    for (var i = 0, bin = atob(str.replace(/[ \r\n]+$/, "")), hex = []; i < bin.length; ++i) {
      let tmp = bin.charCodeAt(i).toString(16);
      if (tmp.length === 1) tmp = "0" + tmp;
      hex[hex.length] = tmp;
    }
    return hex.join("");
  }
  // only single file upload
  varifyFileType(file: File) {

    this.stService.showProgressbar();

    var mimeMatched: boolean = false;
    var sizeInLimit: boolean = false;

    this.errorMessage = "";
    let reader = new FileReader();
    reader.readAsDataURL(file)
    reader.onload = (e) => {
      try {
        debugger;
        var d = (reader.result);
        this.physicalFileName = d.toString();
        var fileData = this.physicalFileName.split(",")[1];
        var hexData = this.byte64ToHex(fileData);
        console.log('hex data');
        console.log(hexData);

        for (var i = 0; i < this.mimeList.length; i++) {
          var dt = this.mimeList[i];
          debugger;
          var len = dt.HexSignature.length;
          var fileHexData = hexData.substr(0, len);
          if (dt.HexSignature.toUpperCase() == fileHexData.toUpperCase()) {
            mimeMatched = true;

            if (file.size <= dt.MaxFileSize) {
              sizeInLimit = true;
              break;
            }
          }
        }

        if (mimeMatched == true && sizeInLimit == true) {
          this.uploadedFileName = file.name;
          console.log(this.uploadedFileName);
          this.canUpload = true;
        }
        else {
          if (!mimeMatched) {
            this.errorMessage = "Invalid uploaded file. File signature not matched.";
          }
          else if (!sizeInLimit) {
            this.errorMessage = "File size exceed. Max size " + (this.mimeList[0].MaxFileSize / (1024 * 1024)) + " Mega Byte(s).";
          }
        }
      }
      catch (ex) { }
      this.stService.hideProgressbar();
    };
  }

  // multiple file upload
  filesDataArray: any[] = [];
  filesErrorDataArray: any[] = [];
  varifyFilesType(files: File[]) {

    this.stService.showProgressbar();

    if (files) {
      this.filesDataArray = [];
      this.filesErrorDataArray = [];
      files.forEach((k) => {
        var mimeMatched: boolean = false;
        var sizeInLimit: boolean = false;

        // this.errorMessage = "";

        let reader = new FileReader();
        reader.readAsDataURL(k)
        reader.onload = (e) => {
          try {
            var d = (reader.result);
            // this.physicalFileName = d.toString();
            var fileData = d.toString().split(",")[1];
            var hexData = this.byte64ToHex(fileData);
            console.log('hex data');
            console.log(hexData);

            for (var i = 0; i < this.mimeList.length; i++) {
              var dt = this.mimeList[i];
              //debugger;
              var len = dt.HexSignature.length;
              var fileHexData = hexData.substr(0, len);
              debugger;
              if (dt.HexSignature.toUpperCase() == fileHexData.toUpperCase()) {
                mimeMatched = true;
                debugger;
                if (k.size <= dt.MaxFileSize) {
                  sizeInLimit = true;
                  break;
                }
              }
            }
            debugger;
            if (mimeMatched == true && sizeInLimit == true) {
              // this.uploadedFileName = key.name;
              this.filesDataArray.push({ PhysicalFileName: d.toString(), UploadedFileName: k.name });
              // console.log(this.uploadedFileName);
              this.canUpload = true;
            }
            else {
              if (!mimeMatched) {
                this.filesErrorDataArray.push({ Message: "<b>" + k.name + "</b> : Invalid uploaded file. File signature not matched." });
                // this.errorMessage = "Invalid uploaded file. File signature not matched.";
              }
              else if (!sizeInLimit) {
                this.filesErrorDataArray.push({ Message: "<b>" + k.name + "</b> : File size exceed. Max size " + (this.mimeList[0].MaxFileSize / (1024 * 1024)) + " Mega Byte(s)." });
                // this.errorMessage = "File size exceed. Max size " + (this.mimeList[0].MaxFileSize / (1024 * 1024)) + " Mega Byte(s).";
              }
            }
          }
          catch (ex) { }
          if (this.filesErrorDataArray) {
            let err_msg = this.filesErrorDataArray.map(function (obj) { return obj.Message; }).join("<br>");
            this.errorMessage = this.sanitizer.sanitize(SecurityContext.HTML, err_msg); //JSON.stringify(this.filesErrorDataArray).toString();
          }

          this.stService.hideProgressbar();
        };

      });
    }

  }

  // for single upload
  // upload() {
  //   this.successMessage = "";
  //   this.errorMessage = "";
  //   if (this.uploadedFileName && this.uploadedFileName.length > 0 && this.physicalFileName && this.physicalFileName.length > 0) {
  //     this.stService.showProgressbar();
  //     var param = {
  //       AttachmentTypeId: this.attachmentTypeId,
  //       UploadedFileName: this.uploadedFileName, 
  //       KeyId: this.key,
  //       PhysicalFileName: this.physicalFileName,
  //     };
  //     console.log(param);
  //     console.log(this.postController);
  //     this.http.post(this.postController, param, true).subscribe(d => {
  //       this.stService.hideProgressbar();
  //       var data = JSON.parse(JSON.stringify(d));
  //       console.log(data);
  //       if (data) {
  //         this.successMessage = "File uploaded successfully."

  //         setTimeout(() => {
  //           this.onUploaded.emit(data);
  //           this.clear();
  //         }, 2000);
  //       }
  //       else {
  //         this.errorMessage = "File upload failed.";
  //       }
  //     }, e => {
  //       this.stService.hideProgressbar();
  //       this.errorMessage = "File Upload Failed.";
  //       this.onUploaded.emit(null);
  //     });
  //   }
  //   else {
  //     this.errorMessage = "Invalid file.";
  //   }
  // }

  filesSuccessDataArray: any[] = [];
  upload() {

    if (this.isMultiple) {

      this.stService.showProgressbar();
      this.filesSuccessDataArray = [];
      this.filesErrorDataArray = [];

      if (this.filesDataArray) {

        this.filesDataArray.forEach((k) => {
          if (k.UploadedFileName && k.UploadedFileName.length > 0 && k.PhysicalFileName && k.PhysicalFileName.length > 0) {
            this.stService.showProgressbar();
            var param = {
              AttachmentTypeId: this.attachmentTypeId,
              UploadedFileName: k.UploadedFileName,
              KeyId: this.key,
              PhysicalFileName: k.PhysicalFileName,
            };
            console.log(param);
            console.log(this.postController);
            this.http.post(this.postController, param, true).subscribe(d => {
              this.stService.hideProgressbar();
              var data = JSON.parse(JSON.stringify(d));
              console.log(data);
              if (data) {

                this.filesSuccessDataArray.push({ Message: "<b>" + k.UploadedFileName + "</b> : File uploaded successfully." });
                // this.successMessage = "File uploaded successfully."

                setTimeout(() => {
                  this.onUploaded.emit(data);
                  this.clear();
                }, 2000);
              }
              else {
                this.filesErrorDataArray.push({ Message: "<b>" + k.UploadedFileName + "</b> : File upload failed." });
                // this.errorMessage = "File upload failed.";
              }
            }, e => {
              this.stService.hideProgressbar();
              // this.errorMessage = "File Upload Failed.";
              this.filesErrorDataArray.push({ Message: "<b>" + k.UploadedFileName + "</b> : File upload failed." });
              this.onUploaded.emit(null);
            });
          }
          else {
            // this.errorMessage = "Invalid file.";
            this.filesErrorDataArray.push({ Message: "<b>" + k.UploadedFileName + "</b> : Invalid file." });
          }
        });
        this.stService.showProgressbar();

      } else {
        this.stService.showProgressbar();
        // this.errorMessage = "File Not Found.";
        this.filesErrorDataArray.push({ Message: "File Not Found." });
      }

      if (this.filesErrorDataArray) {
        let err_msg = this.filesErrorDataArray.map(function (obj) { return obj.Message; }).join("<br>");
        this.errorMessage = this.sanitizer.sanitize(SecurityContext.HTML, err_msg);
      }

      if (this.filesSuccessDataArray) {
        let succ_msg = this.filesSuccessDataArray.map(function (obj) { return obj.Message; }).join("<br>");
        this.successMessage = this.sanitizer.sanitize(SecurityContext.HTML, succ_msg);
      }


    } else {

      this.successMessage = "";
      this.errorMessage = "";
      if (this.uploadedFileName && this.uploadedFileName.length > 0 && this.physicalFileName && this.physicalFileName.length > 0) {
        this.stService.showProgressbar();
        var param = {
          AttachmentTypeId: this.attachmentTypeId,
          UploadedFileName: this.uploadedFileName,
          KeyId: this.key,
          PhysicalFileName: this.physicalFileName,
        };
        console.log(param);
        console.log(this.postController);
        this.http.post(this.postController+"?fileId="+this.fileId, param, true).subscribe(d => {
          this.stService.hideProgressbar();
          var data = JSON.parse(JSON.stringify(d));
          console.log(data);
          if (data) {
            this.successMessage = "File uploaded & changed successfully."

            setTimeout(() => {
              this.onUploaded.emit(data);
              this.clear();
            }, 2000);
          }
          else {
            this.errorMessage = "File upload failed.";
          }
        }, e => {
          this.stService.hideProgressbar();
          this.errorMessage = "File Upload Failed.";
          this.onUploaded.emit(null);
        });
      }
      else {
        this.errorMessage = "Invalid file.";
      }

    }

  }



}
