import { Component, OnInit } from '@angular/core';
import { StaticService } from "../services/static.service"
import { Router, ActivatedRoute } from "@angular/router"
import { MyProgressChange } from "../interfaces/my-progress-change";
import { Authdata } from '../classes/authdata.class';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  progressChange: MyProgressChange;
  constructor(private staticService: StaticService, private router: Router, private route: ActivatedRoute) {
    this.progressChange = this.staticService.progressChange;
  }
  isVal = this.staticService.isSlideBarShow;

  //
  copyrightYear = this.staticService.copyRightYear;
  companyDomain = this.staticService.companyDomain;
  title = 'APPLICATION NAME';
  logoUrl = "../assets/images/Logo.png";
  barUrl = "../assets/images/Rod_Bar.png";

  progressColor = this.staticService.progressColor;
  progressMode = this.staticService.progressState;
  progressValue = this.staticService.progressValue;
  bufferValue = this.staticService.bufferValue;

  //isLoadingProgressShow : any;
  //this.isLoadingProgressShow = this.staticService.isLoadingProgressShow;

  // window.addEvenListener('adf', function(){

  // } );
  // productList: any[] = [{ ProductID: 1, ProductName: 'German' }, { ProductID: 1, ProductName: 'German' }];

  ngOnInit() {

    let scode = parseInt(this.route.snapshot.paramMap.get('sCode'));

    console.log(scode);

    let userName = new Authdata().getData();
    if (userName == null && (scode <= 0 || scode == NaN)) {
      this.router.navigate(['/login']);
    }
  }

}
