import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductSelectComponent } from './components/product-select/product-select.component';
import { DxTextBoxModule, DxDataGridModule, DxButtonModule, DxPopupModule, DxLookupModule, DxDropDownBoxModule, DxFileUploaderModule } from 'devextreme-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';

@NgModule({
  declarations: [ProductSelectComponent, FileUploaderComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DxTextBoxModule,
    DxLookupModule,
    DxDropDownBoxModule,
    DxDataGridModule,
    DxButtonModule,
    DxPopupModule,
    DxFileUploaderModule
  ]
  ,exports: [ProductSelectComponent, FileUploaderComponent]
})
export class StccommonModule { }
