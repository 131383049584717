import { Component, OnInit } from '@angular/core';
import { StaticService } from "../../services/static.service"

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.css']
})
export class NotfoundComponent implements OnInit {

  constructor(private staticService: StaticService) { }

  ngOnInit() {
  }

  errorText = this.staticService.getNotFoundError();

}
