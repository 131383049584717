import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { StaticService } from 'src/app/services/static.service';
import { HttpService } from 'src/app/services/http.service';
import notify from 'devextreme/ui/notify';
import { Authdata } from 'src/app/classes/authdata.class';
import { ClientFeedbackDataModel } from 'src/app/classes/clientfeedback.class';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-client-feedback',
  templateUrl: './client-feedback.component.html',
  styleUrls: ['./client-feedback.component.css']
})
export class ClientFeedbackComponent implements OnInit {

  code$: Observable<string>;
  code: string;


  ratingClicked: number;
  itemIdRatingClicked: string;
  clientFeedbackGridData: any[] = [];

  // [
  //   { 'QId': 1, 'rating': 1, 'Question': 'Assurance of Supply' },
  //   { 'QId': 2, 'rating': 1, 'Question': 'Quality' },
  //   { 'QId': 3, 'rating': 1, 'Question': 'Service' },
  //   { 'QId': 4, 'rating': 1, 'Question': 'Cost (Effective)' },
  //   { 'QId': 5, 'rating': 1, 'Question': 'Innovation' },
  //   { 'QId': 6, 'rating': 1, 'Question': 'Regulatory Compliance' },
  //   { 'QId': 7, 'rating': 1, 'Question': 'Environmental Compliance' },
  // ];

  question: any[] = [
    { QId: 1, Opt1: 'Less than one year', Opt2: '1-3 years', Opt3: '3-5 years', Opt4: 'More than 5 years' },
  ]

  question1: any[] = [];
  question2: any[] = [];

  constructor(private stService: StaticService, private http: HttpService, private route: ActivatedRoute, private router: Router) {
    if (this.scode && this.ccode) {
      this.loadAllDropdownData(this.scode, this.ccode, this.mcode);
    } else {
      // let userName = new Authdata().getData();
      // if (userName == null) {
      //   this.router.navigate(['/login']);
      // }
    }
  }

  scode: number;
  ccode: number;
  mcode: number;
  ngOnInit() {
    this.initForm();

    let scode = parseInt(this.route.snapshot.paramMap.get('sCode'));
    let ccode = parseInt(this.route.snapshot.paramMap.get('cCode'));
    let mcode = parseInt(this.route.snapshot.paramMap.get('mCode'));

    console.log(scode + "----" + ccode + "----" + mcode);

    this.scode = scode;
    this.ccode = ccode;
    this.mcode = mcode;

    this.loadAllDropdownData(this.scode, this.ccode, this.mcode);

    this.prepareDefaultDataSet();

  }

  prepareDefaultDataSet() {
    this.question1 = [
      { id: 1, name: "Less than one year" },
      { id: 2, name: "1-3 years" },
      { id: 3, name: "3-5 years" },
      { id: 4, name: "More than 5 years" }
    ];
    this.question2 = [
      { id: 1, name: "Item Category wise" },
      { id: 2, name: "Overall" }
    ];
  }

  onValueQ2Changed(e) {
    this.selectedItems = [];
    this.gridBoxValue_Category = undefined;
    this.feedbackForm.controls.Question4Ans.setValue(undefined);
    if (e.value == 1) {
      this.feedbackForm.controls.Question5Ans.setValue(undefined);
      this.feedbackForm.controls.Question6Ans.setValue(undefined);
    }

  }

  fbStep: number = 1;
  secTabHeaderText: string = "";
  selectedItems_root: any[] = [];
  selectedItems: any[] = [];
  selectedItemsEnnabled
  next(model: any) {
    let obj: ClientFeedbackDataModel = model.value;
    if (
      (!model.invalid && obj.Question3Ans == 1 && obj.Question4Ans) || // && obj.Question5Ans > 0 && obj.Question6Ans > 0
      (!model.invalid && obj.Question3Ans == 2 && obj.Question5Ans > 0 && obj.Question6Ans > 0)
    ) {
      this.selectedItems_root = [];
      this.fbStep = 2;
      this.secTabHeaderText = this.question2.find(x => x.id == this.q2).name + ' - Satisfaction of product & service';
      console.log(this.q2);

      this.secTabHeaderText = this.secTabHeaderText.split('->').join(" / ");

      // let res = this.dropdownList.includes(this.gridBoxValue_Category)
      let selected = this.feedbackForm.controls.Question3Ans.value;
      let filtered = (selected == 1) ? this.dropdownList.filter(({ HeadID }) => this.gridBoxValue_Category.includes(HeadID)) : [];

      this.selectedItems_root = filtered;

      if (this.selectedItems_root) {
        // console.log(this.selectedItems);
        let c = 1;
        this.selectedItems_root.forEach((key) => {
          key["SLNo"] = c;
          key["Questionnaire"] = this.clientFeedbackGridData;

          if (c == 1) {
            this.selectedItems.push(key);
          }

          c++;
        });
      }

      // = this.selectedItems_root[0];

      // console.log(filtered);
      // console.log(this.gridBoxValue_Category);
      // console.log(this.gridBoxValue_Category.length);
    } else {
      notify("Please fill all required fields.", "warning", 2000);
    }

  }

  notifyMe(e) {
    console.log(e);
  }

  back() {
    this.fbStep = 1;
  }

  dropdownList: any[] = [];
  loadAllDropdownData(scode: number, ccode: number, mcode: number) {
    if (scode > 0) {
      let api = this.stService.feedbackApi;
      console.log(api);
      this.stService.showProgressbar();
      this.http.get(api + "?sCode=" + scode + "&cCode=" + ccode + "&mCode=" + mcode).subscribe(
        d => {
          this.stService.hideProgressbar();
          let result = JSON.parse(JSON.stringify(d));
          console.log(result);
          this.dropdownList = result.GroupList;
          if (result && result.QuestionList) {
            let sl = 1;
            result.QuestionList.forEach(i => {
              i["rating"] = 1;
              i["SelectedRatingContent"] = i['Scale_1'];
              i.SL = sl;

              sl++;
            });
          }
          this.clientFeedbackGridData = result.QuestionList;
        },
        e => {
          this.stService.hideProgressbar();
          // this.router.navigate(['/login']);
          //console.log("Error: " + e.error.Message);
          notify("Error! Server Connection Refused.", "error", 1000);
        }
      );
    } else {
      this.router.navigate(['/login']);
    }

  }

  gridBoxValue: any;
  gridItemBoxDisplayExpr(item) {
    // return item && item.HeadName;
    return item && item.ProductName;
  }

  gridBoxValue_Category: any;
  gridItemBoxDisplayExpr_Category(item) {
    // return item && item.HeadName;
    return item && item.ProductName;
  }


  ratingComponentClick(clickObj: any, typ: number, pId?: number): void {
    if (typ == 1) {
      const item = this.clientFeedbackGridData.find(((i: any) => i.QID === clickObj.itemId));
      // console.log(clickObj);
      // console.log(item);
      // console.log(this.clientFeedbackGridData);
      // console.log(item['Scale_' + clickObj.rating]);
      if (!!item) {
        item.rating = clickObj.rating;
        item.SelectedRatingContent = item['Scale_' + clickObj.rating];
        // this.ratingClicked = clickObj.rating;
        // this.itemIdRatingClicked = item.company;
      }
    } else {

      // this.selectedItems.map((i)=>{
      //   return {
      //     HeadName: i.HeadName,
      //     ProductName: i.ProductName,
      //     Questionnaire: this.group.filter((x) => i.budget === x.budget)
      //   }
      // })

      console.log(clickObj);
      console.log(this.selectedItems);
      this.selectedItems.forEach((k) => {
        if (k.ProductID == pId) {
          k.Questionnaire.forEach((q) => {
            if (q.QID == clickObj.itemId) {
              q.rating = clickObj.rating;
              q.SelectedRatingContent = q['Scale_' + clickObj.rating];
            }
          });
        }
      });
      this.selectedItems = this.selectedItems;
      console.log(this.selectedItems);

      // console.log(pId);
      // console.log(clickObj.itemId);
      // console.log(this.selectedItems);
      // let selectedItem = this.selectedItems.find(x=>x.ProductID == pId);
      // // console.log(selectedItem);
      // let item = selectedItem.Questionnaire.find(((i: any) => i.QID === clickObj.itemId));
      // // console.log(clickObj);
      // console.log(item);
      // // console.log(this.clientFeedbackGridData);
      // // console.log(item['Scale_' + clickObj.rating]);
      // if (!!item) {
      //   item.rating = clickObj.rating;
      //   item.SelectedRatingContent = item['Scale_' + clickObj.rating];
      //   // this.ratingClicked = clickObj.rating;
      //   // this.itemIdRatingClicked = item.company;

      //   item = item;
      // }
    }
  }

  edit() {
    console.log(this.clientFeedbackGridData);
  }

  onValueChanged(e, cell) {
    console.log(e);
    console.log(cell);
  }

  q1: any;
  q2: any;
  // save() {

  //   let selected = this.feedbackForm.controls.Question3Ans.value;

  //   console.log(this.selectedItems);
  //   console.log(this.feedbackForm.value);

  //   if (selected == 1) {
  //     alert("Item category wise");
  //   } else if (selected == 2) {
  //     alert("Overall");
  //   } else {
  //     alert("Unknown");
  //   }

  //   // console.log(this.q1);
  //   // console.log(this.q2);
  //   console.log(this.feedbackForm.value);
  //   console.log(this.clientFeedbackGridData);
  //   // let api = this.stService.feedbackApi;
  //   // this.stService.showProgressbar();
  //   // this.http.post(api + "/RFF", this.clientFeedbackGridData).subscribe(d => {
  //   //   this.stService.hideProgressbar();
  //   //   var val = JSON.parse(JSON.stringify(d));
  //   //   console.log(val);
  //   //   if (val && val == 200) {

  //   //     // this.getOrgClients();

  //   //     // this.popupVisible = false;

  //   //     notify("Thank you for your valuable feedback.", "success", 2000);
  //   //   }
  //   //   else {
  //   //     notify("Your request has been faild. Please try again.", "error", 2000);
  //   //   }
  //   // }, e => {
  //   //   this.stService.hideProgressbar();
  //   //   if (e.status == 400 && e.error != null) {
  //   //     notify(e.error.Message, "error", 2000);
  //   //   } else {
  //   //     notify("Error! Server Connection Refused.", "error", 2000);
  //   //   }
  //   // });

  // }

  isNotPosssibleToBack: boolean = false;
  overalFBSave(sigleOrMultiple: number, itemNo?: number) {
    let master = this.feedbackForm.value as ClientFeedbackDataModel;
    master.Question2Ans = master.Question2Ans.toString();
    console.log(master);
    console.log(this.feedbackForm.value);
    console.log(this.clientFeedbackGridData);

    let reqUrl = "";

    let obj = {
      FBMaster:
      {
        YearOfBusinessWithClient: master.Question1Ans,
        ProcuredItems: master.Question2Ans,
        FeedbackTypeID: master.Question3Ans,
        NoOfPOLastYear: master.Question5Ans,
        ProcurementVolume: master.Question6Ans,
        SellerID: this.scode,
        BuyerID: this.ccode
      },
      FBDetails: []
    };

    if (sigleOrMultiple == 1 && this.clientFeedbackGridData) {
      reqUrl = "/RFF_Overal";
      let detList: any[] = [];
      this.clientFeedbackGridData.forEach((key) => {
        detList.push({ QID: key.QID, FeedbackScaleNo: key.rating, Weightage: key.Weightage, TotalWeightedScore: key.Score });
      });

      obj.FBDetails = detList;
    }

    if (sigleOrMultiple == 2 && this.selectedItems) {
      reqUrl = "";
      let detList: any[] = [];
      this.selectedItems[0].Questionnaire.forEach((key) => {
        detList.push({ QID: key.QID, FeedbackScaleNo: key.rating, Weightage: key.Weightage, TotalWeightedScore: key.Score });
      });

      obj.FBDetails = detList;
    }

    let api = this.stService.feedbackApi;
    this.stService.showProgressbar();
    this.http.post(api + reqUrl, obj).subscribe(d => {
      this.stService.hideProgressbar();
      var val = JSON.parse(JSON.stringify(d));
      console.log(val);
      if (val && val == 200) {

        // this.getOrgClients();
        debugger;
        // this.popupVisible = false;
        if (sigleOrMultiple == 2) {
          this.selectedItems = [];
          this.isNotPosssibleToBack = true;
          var index = this.selectedItems_root.findIndex(s => s.SLNo == itemNo);
          this.selectedItems_root.splice(index, 1);
          let itm = itemNo + 1;
          let isExist = this.selectedItems_root.filter(x => x.SLNo == itm);
          if (isExist.length > 0) {
            isExist.forEach((k) => {
              k.Questionnaire.forEach((q) => {
                q["rating"] = 1;
                q["SelectedRatingContent"] = q['Scale_1'];
              });
            });
            this.selectedItems = isExist;
          } else {
            this.selectedItems = [];
            notify("Thank you for your valuable feedback.", "success", 2000);

            this.router.navigate(['/thank-you/' + this.scode + "/" + this.ccode + "/" + this.mcode]);
          }

        }

        if (sigleOrMultiple == 1) {
          notify("Thank you for your valuable feedback.", "success", 2000);

          this.router.navigate(['/thank-you/' + this.scode + "/" + this.ccode + "/" + this.mcode]);
        }

      }
      else {
        notify("Your request has been faild. Please try again.", "error", 2000);
      }
    }, e => {
      this.stService.hideProgressbar();
      if (e.status == 400 && e.error != null) {
        notify(e.error.Message, "error", 2000);
      } else {
        notify("Error! Server Connection Refused.", "error", 2000);
      }
    });
  }

  //#region Form event's
  // master-details form property declaration and initialization
  feedbackForm;
  initForm(obj?: ClientFeedbackDataModel) {

    this.feedbackForm = new FormGroup({
      Question1Ans: new FormControl(obj ? obj.Question1Ans : undefined, Validators.compose([
        Validators.required
      ])),
      Question2Ans: new FormControl(obj ? obj.Question2Ans : undefined, Validators.compose([
        Validators.required
      ])),
      Question3Ans: new FormControl(obj ? obj.Question3Ans : undefined, Validators.compose([
        Validators.required
      ])),
      Question4Ans: new FormControl(obj ? obj.Question4Ans : undefined, []),
      Question5Ans: new FormControl(obj ? obj.Question5Ans : undefined, []),
      Question6Ans: new FormControl(obj ? obj.Question6Ans : undefined, [])
    });
  }
  //#endregion

}
