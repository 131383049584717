import { Component, OnInit, NgZone, NgModule, Output, EventEmitter } from '@angular/core';
import { StaticService } from "../../services/static.service";
import { HttpService } from "../../services/http.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Authdata } from "../../classes/authdata.class";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import notify from "devextreme/ui/notify";
import { DashboardDataModel } from 'src/app/classes/dashboard.class';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {

  connectionEstError: boolean = false; // 
  dashboardApi: any = "Dashboard";
  private logData;
  public userName: string;
  public userId: string;
  public responsibilityCheck: string;
  public applicationId: string;
  public applicationName: string;

  public iconName: string = "settings_power";
  private api: string = "application";

  public sideNavOpen: boolean = true;
  public sideNavMode: string = "side";

  public favMenus;

  public profiles;

  public selectedResponsibilities;

  // fromFiscalDate: Date = new Date();
  // toFiscalDate: Date = new Date();

  popupOpeningVisible: boolean;
  @Output() onMenuLoaded = new EventEmitter<boolean>();
  form: FormGroup;
  screenwidth: any = 550;

  constructor(private ngZone: NgZone, private stService: StaticService
    , private http: HttpService
    , private router: Router, private activeRoute: ActivatedRoute) {

    ////console.log(this.staticService.menuChanges);
    //this.menus = this.staticService.menuChanges;
    this.screenwidth = window.innerWidth;
    if(this.screenwidth<550){
      this.screenwidth = window.innerWidth;
    }else{
      this.screenwidth = 550;
    }
    setInterval(() => {
      this.clockText = new Date();
    }, 1);


    // window.onresize = (e) =>
    // {
    //    ngZone.run(() => {
    //     this.sidenavAdjust(window.innerWidth);
    //   });
    // }
  }

  ngOnInit() {
    //  this.generateMenu();
    //  //console.log('current menu permission dashboard' + JSON.stringify( this.currentMenuPermission));
    this.logData = new Authdata().getData();

    if (this.logData) {

      this.userName = this.logData.UserName;
      this.userId = this.logData.UserId;

      let responsibility = this.stService.GetUserSelectedResponsibility();

      var str = responsibility.Name.replace(/\s+/g, '');
      var final = str.toLowerCase();
      this.responsibilityCheck = final;
      console.log(this.responsibilityCheck);

      let res = (responsibility && responsibility.Name) ? this.stService.ConvertToCapitalizeCaseWord(responsibility.Name) : 'None';

      this.userAndResponsibility = " [ " + res + " ]";

      //  this.activeRoute.queryParams.subscribe(p=>{
      //    this.sidenavAdjust(window.innerWidth);
      //  });

      // this.getFavoriteList();

      this.getObjList();
    } else {
      this.connectionEstError = true;
    }

  }

  dashboardData: DashboardDataModel = new DashboardDataModel();
  getObjList() {
    this.stService.showProgressbar();
    this.http.get(this.dashboardApi).subscribe(
      d => {
        this.stService.hideProgressbar();
        let res = JSON.parse(JSON.stringify(d));
// debugger;
        console.log(res);

        this.dashboardData = res.DashboardData as DashboardDataModel;

        this.popupOpeningVisible = true;

        // this.productDropDownList = res.ProductList;

        // debugger;
        //console.log(res);
      },
      e => {
        this.stService.hideProgressbar();
        //console.log("Error: " + e.error.Message);
        //notify("Error! Server Connection Refused.", "error", 1000);
        this.connectionEstError = true;
      }
    );
  }

  popupVisible: boolean = false;
  popup_contents: any[] = [
    {
      id: 1, heading: 'Email Verification', description: 'Email verification done.', footer: '', pop_set_dragEnabled: false,
      pop_set_title: '', pop_set_showTitle: false, pop_set_closeOnOutsideClick: true, pop_set_width: 300, pop_set_showCloseButton: false
    },
    {
      id: 2, heading: 'Mobile Number Verification', description: 'Mobile number verification details', footer: '', pop_set_dragEnabled: true,
      pop_set_title: '', pop_set_showTitle: true, pop_set_closeOnOutsideClick: false, pop_set_width: 500, pop_set_showCloseButton: true
    },
    {
      id: 3, heading: 'Create Organization Profile', description: 'Create your organization profile', footer: '', pop_set_dragEnabled: false,
      pop_set_title: '', pop_set_showTitle: false, pop_set_closeOnOutsideClick: false, pop_set_width: 800, pop_set_showCloseButton: true
    },
    {
      id: 4, heading: 'Physical Verification', description: 'Physical verification details', footer: '', pop_set_dragEnabled: false,
      pop_set_title: '', pop_set_showTitle: false, pop_set_closeOnOutsideClick: false, pop_set_width: 300, pop_set_showCloseButton: true
    }
  ];
  selected_popup_content: any = {};
  open_popup(e, num: number) {
    if (num == 3) {
      this.goToCreateOrg();
    } else {
      this.selected_popup_content = this.popup_contents.find(x => x.id == num);
      this.popupVisible = true;
    }
  }

  closeOpeningPopup(e){
    this.popupOpeningVisible = false;
  }

  openQueueForm() {
    this.router.navigate(["admin/verification/seller-org-queue"]);
  }

  openProductQueueForm() {
    this.router.navigate(["admin/verification/product-queue"]);
  }

  close_popup(e) {
    this.popupVisible = false;
  }
  //
  dwrVarialble: boolean = false;
  buttonIcon: any = "remove";
  buttonText: any = "Close";
  userAndResponsibility: string = "";

  clockText: Date = new Date();
  drawerToggle() {
    //console.log("Drawer Clicked!");

    if (this.dwrVarialble) {
      this.buttonIcon = "remove";
      this.buttonText = "Close";

      this.dwrVarialble = false;
    } else {
      this.buttonIcon = "remove";
      this.buttonText = " Close";

      this.dwrVarialble = true;
    }
  }

  fltVarialble: boolean = false;
  floatingButtonIcon: any = "more";
  floatingToggle() {
    //console.log("Floating Button Clicked!");

    if (this.fltVarialble) {
      this.floatingButtonIcon = "more";

      this.fltVarialble = false;
    } else {
      this.floatingButtonIcon = "remove";

      this.fltVarialble = true;
    }
  }

  goToProfile() {
    this.router.navigate(["acc/setup/profile"]);
  }

  goToCreateOrg() {
    this.router.navigate(["org/setup/profile"]);
  }

}