import { Component, OnInit } from '@angular/core';
import { StaticService } from '../../services/static.service';
import { HttpService } from '../../services/http.service';
import { Authdata } from '../../classes/authdata.class';

@Component({
  selector: 'app-topmenu',
  templateUrl: './topmenu.component.html',
  styleUrls: ['./topmenu.component.css']
})
export class TopmenuComponent implements OnInit {

  public applications: any;
  public reports: any;
  public logData;
  public selectedResponsibilities;
  public profiles: any;

  constructor(private staticService: StaticService, private http: HttpService) { }

  ngOnInit() {
    if (sessionStorage.getItem(this.staticService.applicationListName) != null) {
      this.applications = JSON.parse(sessionStorage.getItem(this.staticService.applicationListName));

      if (sessionStorage.getItem(this.staticService.reportMenuListName) != null)
        this.reports = JSON.parse(sessionStorage.getItem(this.staticService.reportMenuListName));
    }
    else {
      this.logData = new Authdata().getData();
      // if(this.logData){
      this.http.get(this.staticService.applicationApi).subscribe(
        (data) => {
          //console.log(data);
          if (data) {
            var json = JSON.parse(JSON.stringify(data));
            this.applications = json.MENUS;
            this.reports = json.REPORTS;
            sessionStorage.setItem(this.staticService.applicationListName, JSON.stringify(this.applications));
            sessionStorage.setItem(this.staticService.reportMenuListName, JSON.stringify(this.reports));
          }
          else {
            alert("Application list failed to load");
          }
          //this.staticService.hideProgressbar();
        }
        , (error) => {
          alert("Application list failed to load");
        }
      );
      // }
      // else{
      //   alert("Unauthorized Access!");
      // }
    }
  }

}
