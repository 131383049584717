import { Component, OnInit, Input, OnChanges, SimpleChange } from '@angular/core';
import { StaticService } from '../../services/static.service';
import { Router } from '@angular/router';
import { Authdata } from 'src/app/classes/authdata.class';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  connectionEstError: boolean = false; // 
  @Input() MENU;
  contextMenuitems_1: any;
  contextMenuitems_2: any;
  public menus;
  public fevMenus;

  public reports;
  // public fevMenus;

  public currentMenuPermission;

  userName;
  userId;
  firstLetter;

  constructor(private staticService: StaticService, private http: HttpService, private router: Router) {
    // this.contextMenuitems_1 = [{ text: 'Add to Favorite', icon: 'dx-icon dx-icon-favorites' }];
    // this.contextMenuitems_2 = [{ text: 'Delete from Favorites', icon: 'dx-icon dx-icon-trash' }];
  }


  ngOnInit() {
    this.getSetMenu();
  }
  ngOnChanges(data) {
    console.log(data);
    //  debugger;
    if (data.MENU && data.MENU.currentValue) {
      this.menus = data.MENU.currentValue;
      // console.log(this.menus);

      // this.fevMenus = this.loadFevMenuData(this.menus);

      // this.showMenuSearch(this.loadMenuSearchData(this.menus));
    }

    // if (data.REPORT && data.REPORT.currentValue) {
    //   this.reports = data.REPORT.currentValue;
    //   // console.log(this.menus);
    // }
  }

  flatOrDropdown_Menu: boolean = true; // flat = true, dropdown = false
  getSetMenu() {
    var logData = new Authdata().getData();
    if (logData) {
      this.userName = logData.UserName;
      this.userId = logData.UserId;
      this.firstLetter = logData.UserName.charAt(0).toUpperCase();

      /// Admin 
      let responsibility = this.staticService.GetUserSelectedResponsibility();
      let res = (responsibility && responsibility.Name) ? this.staticService.ConvertToCapitalizeCaseWord(responsibility.Name) : 'None';

      this.flatOrDropdown_Menu = (res == 'Admin' || res == 'Site admin') ? false : true;

      //console.log(sessionStorage.getItem(this.staticService.applicationListName));
      if (sessionStorage.getItem(this.staticService.applicationListName)) {
        this.menus = JSON.parse(sessionStorage.getItem(this.staticService.applicationListName));

        // this.fevMenus = this.loadFevMenuData(this.menus);

        // if (sessionStorage.getItem(this.staticService.reportMenuListName) != null)
        //   this.reports = JSON.parse(sessionStorage.getItem(this.staticService.reportMenuListName));

        // console.log(this.reports);
        // console.log(this.fevMenus);

        // sessionStorage.setItem("MY_MENU", this.menus);
        // this.showMenuSearch(this.loadMenuSearchData(this.menus));
      }
      // console.log(this.menus);
      // debugger;
    } else {
      this.connectionEstError = true;
    }
  }

  selectItem(e) {
    // if (e.itemData.ROUTE_NAME && e.itemData.type == "MENU") {
    ////console.log(e.itemData.ROUTE_NAME);
    this.router.navigate([e.itemData.ROUTE_NAME]);
    // }
  }

  logoutClick(e) {
    if (confirm("Do you want to Logout?") == true) {
      // //console.log('item clicked');
      // console.log(e);
      // if (e.itemData.id == 111111111) {
      //   new Authdata().clearData();
      //   this.router.navigate(["login"]);
      // }
      new Authdata().clearData();
      this.router.navigate(["login"]);
    }
  }

  refresh(e) {
    console.log("refresh..");
    this.getSetMenu();
    notify("Page Refreshing ..", "info", 2000);
  }

  // MENU SEARCH 
  // loadMenuSearchData(data: any) {
  //   let res: any[] = [];
  //   if (data) {
  //     data.forEach((key) => {
  //       let mg = key.MenuGroup;
  //       let mgR = key.MenuGroupRouting;
  //       let mgId = key.MenuGroupId;
  //       key.Menus.forEach((k) => {
  //         res.push({ "MenuGroup": mg, "MenuGroupId": mgId, "MenuId": k.MenuId, "Menu": k.Menu, "Routing": mgR + '/' + k.Routing, "IsFev": k.IsFev }); //"IsFev": k.IsFev ? "success" : "normal"
  //       });
  //     });
  //   }
  //   // console.log(res);
  //   return res;
  // }

  dataSource: any;
  // showMenuSearch(res: any) {
  //   this.dataSource = new DataSource({
  //     store: new ArrayStore({
  //       data: res,
  //       key: "MenuId",
  //     }),
  //     group: "MenuGroup",
  //     sort: [{ selector: "MenuGroupId", desc: true }]
  //   });
  // }

  // FEV MENU
  // loadFevMenuData(data: any) {
  //   let res: any[] = [];
  //   if (data) {
  //     data.forEach((key) => {
  //       key.FevMenus.forEach((k) => {
  //         res.push({ "MenuGroup": key.MenuGroup, "MenuGroupId": key.MenuGroupId, "MenuId": k.MenuId, "Menu": k.Menu, "Routing": key.MenuGroupRouting + '/' + k.Routing });
  //       });
  //     });
  //   }
  //   // console.log(res);
  //   return res;
  // }

  valueChanged(evt) {
    // console.log(evt);
    this.router.navigateByUrl(evt.value.Routing);
  }

  // settingsBtn(e) {
  //   // notify("Go to Settings Menu", "success", 2000);
  //   this.router.navigate(["admin/setup/profile-settings"]);

  // }
  // notificationBtn(e) {
  //   notify("Go to Notifications Menu", "success", 2000);
  // }
  // fevoriteBtn(data) {
  //   console.log(data);
  //   // let obj = {MID: data.MID};
  //   // data.Fevorite = "success";
  //   this.staticService.showProgressbar();
  //   this.http.post("Application/AddToFavorite?mId="+data.MenuId, null).subscribe(d => {
  //     this.staticService.hideProgressbar();
  //     var val = JSON.parse(JSON.stringify(d));
  //     //console.log(val);
  //     if (val && val == 200) {

  //       // this.router.navigate([e.itemData.ROUTE_NAME]);
  //       // this.router.navigate(["main/app"]);
  //       this.getMenus(JSON.parse(sessionStorage.getItem(this.staticService.selectedResponsibilityKey)).Id);

  //       notify("Data saved successfully.", "success", 1000);
  //     }
  //     else {
  //       notify("Failed to save data.", "error", 1000);
  //     }
  //   }, e => {
  //     this.staticService.hideProgressbar();
  //     //console.log("Error: " + e.error.Message);
  //     if (e.status == 400 && e.error != null) {
  //       notify(e.error.Message, "error", 1000);
  //     } else {
  //       notify("Error! Server Connection Refused.", "error", 1000);
  //     }

  //   });
  // }

  getMenus(id: number) {
    var params = { 'rspid': id };
    this.http.get(this.staticService.applicationApi, params).subscribe(
      (data) => {
        var json = JSON.parse(JSON.stringify(data));
        if (data) {
          ////console.log(this.applications);
          sessionStorage.setItem(this.staticService.applicationListName, JSON.stringify(json.MENUS));
          // sessionStorage.setItem(this.staticService.reportMenuListName, JSON.stringify(json.REPORTS));

          // console.log(JSON.stringify(json.REPORTS));

          // this.fevMenus = this.loadFevMenuData(json.MENUS);

          // this.showMenuSearch(this.loadMenuSearchData(json.MENUS));
        }
        else {
          //alert("Application list failed to load");
          // notify("Welcome to SHURUCHI ERP", "info", 1000);
        }
        //this.staticService.hideProgressbar();
      }
      , (error) => {
        //alert("Application list failed to load");
        // notify("Welcome to SHURUCHI ERP", "info", 1000);
        this.connectionEstError = true;
      }
    );
  }
}