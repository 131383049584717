import { Injectable } from '@angular/core';
import { MyProgressChange } from "../interfaces/my-progress-change";
import { MenuChanges } from "../interfaces/menu-changes";
import { Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
//import printJS from 'print-js';

@Injectable()
export class StaticService {

  public feedbackApi: string = "ClientFeedback";

  constructor() { }

  private menuLoaded = new Subject<boolean>();
  menuLoadStatus = this.menuLoaded.asObservable();
  menuLoadChanged(isMenuExist) {
    this.menuLoaded.next(isMenuExist);
  }
  public selectedResponsibilityKey = "selectedResponsibilityKey";

  public isNavBarShow: boolean = false;
  public isSlideBarShow: boolean = false;
  public copyRightYear: string = new Date().getFullYear().toString();
  public companyName: string = "ARGON";
  public companyDomain: string = "ARGON.com";

  progressChange: MyProgressChange = { isLoadingProgressShow: false };
  toogleProgressbarShow() {
    this.progressChange.isLoadingProgressShow = !this.progressChange.isLoadingProgressShow;
  }
  showProgressbar() {
    setTimeout(() => {
      this.progressChange.isLoadingProgressShow = true;
    });
  }
  hideProgressbar() {
    setTimeout(() => {
      this.progressChange.isLoadingProgressShow = false;
    });
  }

  printReport(result) {
    // print({printable: result, type: 'pdf', base64: true});
  }

  public progressColor: string = "primary";
  public progressState: string = "indeterminate"
  // public progressState: string ="buffer"
  public progressValue = 50;
  public bufferValue = 75;

  private uiHostUrl: string = "SourceWindow";

  //////local
  // private apiUrl: string = "http://localhost:30967/api/";
  // private reportViewUrl: string = "http://localhost:30967/Reports/Reportviewer.aspx";
  // private hostUrl: string = "http://localhost:30967/";
  // private uiUrl: string = "http://localhost:4000/#/";
  // private publiUIUrl: string = "http://localhost:4200/#/";

  // public apiImageUrl: string = "http://localhost:30967/";
  

  //////server
  private apiUrl: string = "https://apiauth.sourcewindow.com/api/";
  private reportViewUrl: string = "https://apiauth.sourcewindow.com/Reports/Reportviewer.aspx";
  private hostUrl: string = "https://apiauth.sourcewindow.com/";
  private uiUrl: string = "https://auth.sourcewindow.com/#/";
  private publiUIUrl: string = "https://sourcewindow.com/#/";

  public apiImageUrl: string = "https://sourcewindow.com/";


  //////server
  // private apiUrl: string = "http://sourcewi-001-site1.gtempurl.com/api/";
  // private reportViewUrl: string = "http://sourcewi-001-site1.gtempurl.com/Reports/Reportviewer.aspx";
  // private hostUrl: string = "http://sourcewi-001-site1.gtempurl.com/";
  // private uiUrl: string = "http://auth.sourcewindow.com/#/";
  // private publiUIUrl: string = "http://sourcewindow.com/#/";

  // public apiImageUrl: string = "http://sourcewindow.com/";

  // public downloadUrl = this.apiUrl + "DmmDownload";
  // public attachmentUrl: string = "Attachment";


  private tokenApi: string = "token";
  private grantType: string = "password";

  public applicationListName: string = "APPLICATION_KEY";
  public reportMenuListName: string = "REPORTMENU_KEY";
  public profileListName: string = "PROFILES_KEY";

  public menuKey: string = "MENU_KEY";
  // public selectedResponsibilityKey : string = "SELECTED_RESPONSIBILITY_KEY";

  // public selectedPLCORACLEResponsibilityKey = "SELECTED_PLC_ORACLE_RESPONSIBILITY_KEY";


  private httpResultData: any;
  public userNameTitle: string = "UserName";
  public userIdTitle: string = "UserId";

  public selectedApplicationId: string;
  public selectedApplicationName: string;

  //#region pagination data
  length = 100;
  pageSize = 10;
  pageSizeOptions = [5, 10, 15, 20, 25, 50, 100];
  //#endregion

  //#region COMPANY DETAILS
  public getCompanyName(): string {
    return this.companyName;
  }
  public getCompanyDomain(): string {
    return this.companyDomain;
  }
  public getNotFoundError(): string {
    return "Unfortunately, the page you've requested no longer exists. Please contact with " + this.companyName + ", IT Department.";
  }
  //#endregion

  //#region URL DETAILS
  public getHostUrl(): string {
    return this.hostUrl;
  }
  public getApiUrl(): string {
    return this.apiUrl;
  }
  public getPubUiUrl(): string {
    return this.publiUIUrl;
  }
  public getTokenUrl(): string {
    return this.hostUrl + this.tokenApi;
  }
  public getGrantType(): string {
    return this.grantType;
  }
  public getUIUrl(): string {
    return this.uiUrl;
  }
  public getReportUrl() {
    return this.reportViewUrl;
  }
  //#endregion


  //#region Menus
  GetMenus(completeUrl: string) {
    var menuList = sessionStorage.getItem(this.applicationListName);
    return menuList;
  }

  GetReports(completeUrl: string) {
    var reportList = sessionStorage.getItem(this.reportMenuListName);
    return reportList;
  }

  GetUserSelectedResponsibility() {
    return JSON.parse(sessionStorage.getItem(this.selectedResponsibilityKey));
  }

  GetMenuPermissionDetails(completeUrl: string) {

    var menus = JSON.parse(sessionStorage.getItem(this.applicationListName));
    var menuDetails = { CanView: false, CanInsert: false, CanEdit: false, CanDelete: false };

    // //console.log('url in global service' +completeUrl);
    var rt = completeUrl.split("/");
    //console.log(rt);
    if (rt.length >= 4) {
      var firstSegment = rt[1];
      var secondSegment = rt[2];
      var thirdSegment = rt[3];
    }
    //console.log(firstSegment);
    //console.log(secondSegment);
    //console.log(thirdSegment);
    //console.log(menus);
    var allMenus = [];
    var selectedMenu;

    //console.log('menus');
    //console.log(menus);
    menus.forEach(element => {
      //console.log(element);
      element.items.forEach(mg => {
        //console.log(mg);
        mg.items.forEach(m => {
          //console.log(m);
          allMenus.push(m);
        });
      });
    });

    //console.log(allMenus);
    selectedMenu = allMenus.find(s => s.AppRouteName == firstSegment && s.MenuGroupRouteName == secondSegment
      && s.MenuRouteName == thirdSegment);
    //console.log('selected menu');
    //console.log(selectedMenu);


    if (selectedMenu) {
      menuDetails.CanView = selectedMenu.CANVIEW;
      menuDetails.CanInsert = selectedMenu.CANINSERT;
      menuDetails.CanEdit = selectedMenu.CANUPDATE;
      menuDetails.CanDelete = selectedMenu.CANDELETE;
    }
    //console.log('menuDetails');
    //console.log(menuDetails);
    return menuDetails;
  }
  //#endregion

  //#region API LIST
  public applicationApi: string = "Application";
  //#endregion

  //#region common validation rules
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  public convertToShortString(data: string) {
    var acronym = "";
    if (data && data.length > 0) {
      var matches = data.match(/\b(\w)/g);
      acronym = matches.join('');
      acronym = acronym.toUpperCase();
    }
    return acronym;
  }

  public ConvertToCapitalizeCaseWord(word: string) {
    if (!word) {
      return word;
    }
    else {
      let splited_word = word.split(" ");
      console.log(splited_word);
      // let result;
      // splited_word.forEach((wrd) => {
      //   result = wrd[0].toUpperCase() + wrd.substr(1).toLowerCase();
      // });

      return word[0].toUpperCase() + word.substr(1).toLowerCase();
    }
  }

  public generateGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public isGuid(stringToTest) {
    if (stringToTest[0] === "{") {
      stringToTest = stringToTest.substring(1, stringToTest.length - 1);
    }
    var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(stringToTest);
  }

  public getMonthName(monthNo: number): string {
    console.log(monthNo);
    let mlist = [
      { id: 0, name: "January" },
      { id: 1, name: "February" },
      { id: 2, name: "March" },
      { id: 3, name: "April" },
      { id: 4, name: "May" },
      { id: 5, name: "June" },
      { id: 6, name: "July" },
      { id: 7, name: "August" },
      { id: 8, name: "September" },
      { id: 9, name: "October" },
      { id: 10, name: "November" },
      { id: 11, name: "December" }
    ];
    if (monthNo >= 0 && monthNo < 12) {
      return mlist.find(x => x.id == monthNo).name;
    } else {
      return "XXXX";
    }

  };

  fnShowReport(report_id: string, urlList: any) {
    // debugger;
    var keyList = [];
    var counter = 0;
    while (true) {
      var key = sessionStorage.key(counter);
      if (key) {
        var val = sessionStorage.getItem(key);
        if (key.lastIndexOf(report_id) >= 0) {
          keyList.push(key);
        }
      }
      else {
        break;
      }
      counter++;
    }
    keyList.forEach(d => {
      sessionStorage.removeItem(d);
    });

    if (urlList) {
      urlList.forEach(d => {
        if (d.Value) {
          sessionStorage.setItem(report_id + "!" + d.Key, d.Value.toString());
        }
      });
      window.open(this.getUIUrl() + "reportviewer/" + report_id);
    }
  }
  fnGetReport(report_id: string, urlList: any): string {
    // debugger;
    var keyList = [];
    var counter = 0;
    while (true) {
      var key = sessionStorage.key(counter);
      if (key) {
        var val = sessionStorage.getItem(key);
        if (key.lastIndexOf(report_id) >= 0) {
          keyList.push(key);
        }
      }
      else {
        break;
      }
      counter++;
    }
    keyList.forEach(d => {
      sessionStorage.removeItem(d);
    });

    if (urlList) {
      urlList.forEach(d => {
        if (d.Value) {
          sessionStorage.setItem(report_id + "!" + d.Key, d.Value.toString());
        }
      });
      return this.getUIUrl() + "reportviewer/" + report_id;
    }
    else {
      return "";
    }

  }
  //#endregion
}
