import { Component, OnInit } from '@angular/core';
import { StaticService } from 'src/app/services/static.service';
import { HttpService } from 'src/app/services/http.service';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-forgetpass',
  templateUrl: './forgetpass.component.html',
  styleUrls: ['../../../assets/css/login-styles.css', './forgetpass.component.css']
})
export class ForgetpassComponent implements OnInit {

  userIdValue: string = "";
  loginForm;
  encMsg: string;
  errorMsg: string;

  isAnimo: string = "animo-default";
  isLoader: string = "no-loader";

  constructor(private staticService: StaticService,
    private httpService: HttpService, private router: Router) {
  }

  ngOnInit() {
  }

  submitReq(e) {
    if (this.userIdValue != "") {

      this.isLoader = "yes-loader";
      this.httpService.post("ResetLink/Create", { key: this.userIdValue }).subscribe(d => {
        this.isLoader = "no-loader";
        var val = JSON.parse(JSON.stringify(d));
        console.log(val);
        if (val == 200) {

          alert("Please check your e-mail (Inbox/Spam) for reset link.");
        }
        else if (val == 406) {
          this.errorMsg = "Invalid Request!"; //
          this.clearWarning();
        }
        else {
          this.errorMsg = "Unable to send reset email."; //
          this.clearWarning();
        }
      }, e => {
        if (e.status == 400 && e.error != null) {
          this.isLoader = "no-loader";
          this.errorMsg = e.error.Message; //
          this.clearWarning();
        } else {
          notify("Error! Server Connection Refused.", "error", 2500);
        }
      });

    } else {
      this.errorMsg = "Please enter your e-mail address."; //
      this.clearWarning();
    }
  }

  clearWarning() {
    setTimeout(() => {
      this.errorMsg = "";
    }, 5000);
  }

}
