import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-feedback-thank-you',
  templateUrl: './feedback-thank-you.component.html',
  styleUrls: ['./feedback-thank-you.component.css']
})
export class FeedbackThankYouComponent implements OnInit {
  scode: number;
  ccode: number;
  mcode: number;

  constructor(private route: ActivatedRoute, private router: Router) { 
    // if (this.scode && this.ccode) {
      
    // } else {
    //   // let userName = new Authdata().getData();
    //   // if (userName == null) {
    //   //   this.router.navigate(['/login']);
    //   // }
    // }
  }

  ngOnInit() {
    let scode = parseInt(this.route.snapshot.paramMap.get('sCode'));
    let ccode = parseInt(this.route.snapshot.paramMap.get('cCode'));
    let mcode = parseInt(this.route.snapshot.paramMap.get('mCode'));

    console.log(scode + "----" + ccode + "----" + mcode);

    this.scode = scode;
    this.ccode = ccode;
    this.mcode = mcode;

    if(!scode && !ccode && !mcode) {this.router.navigate(['/login']);}

  }

  goBakTo(e){
    this.router.navigate(['/login']);
  }

}
