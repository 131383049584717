import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {StaticService} from "./static.service";
import { map , debounceTime} from 'rxjs/operators';
import {Authdata} from '../classes/authdata.class';

// interface iData{
//     Department: string,
//     DepartmentId: number
// }

@Injectable()
export class HttpService {


  constructor(private httpClient: HttpClient, private staticService: StaticService) { 
  }

  getResponsibility(){
    var rsp = JSON.parse(sessionStorage.getItem(this.staticService.selectedResponsibilityKey));
    return rsp;
  }
  prepareHttpOption(params){
    var authData  = new Authdata().getData();
    var contentType = 'application/json';
    var myToken = authData ? authData.token_type+' '+authData.access_token : '';
    var r =  this.getResponsibility();
   
    var httpHeaders = new HttpHeaders({
      'Content-Type':  contentType,
      'Authorization': myToken ,
      'Accept' : 'q=0.8;application/json;q=0.9',
      'RSPID' : ''+r.Id
    })
    //debugger;
    if(params){
      return {
        headers : httpHeaders,
        params : params
      }
    }
    else{
      return {
        headers : httpHeaders
      }
    }
  }

  prepareHttpOptionParamAsBody(params){
    var authData  = new Authdata().getData();
    var contentType = 'application/json';
    var myToken = authData ? authData.token_type+' '+authData.access_token : '';
    var r = this.getResponsibility();
    
    var httpHeaders = new HttpHeaders({
      'Content-Type':  contentType,
      'Authorization': myToken ,
      'Accept' : 'q=0.8;application/json;q=0.9',
      'RSPID' : ''+r.Id
    })
    //debugger;
    if(params){
      return {
        headers : httpHeaders,
        body  : params
      }
    }
    else{
      return {
        headers : httpHeaders
      }
    }
  }
  prepareHttpOptionWithBody(params, httpBody){
    var authData  = new Authdata().getData();
    var contentType = 'application/json';
    var myToken = authData ? authData.token_type+' '+authData.access_token : '';
    var r = this.getResponsibility();
    //debugger;
    var httpHeaders = new HttpHeaders({
      'Content-Type':  contentType,
      'Authorization': myToken ,
      'Accept' : 'q=0.8;application/json;q=0.9',
      'RSPID' : ''+r.Id
    })
    //debugger;
    if(params){
      return {
        headers : httpHeaders,
        params : params,
        body : httpBody
      }
    }
    else{
      return {
        headers : httpHeaders
      }
    }
  }
  prepareTokenHttpOption(){
    var contentType = 'application/x-www-form-urlencoded';
    var httpHeaders = new HttpHeaders({
      'Content-Type':  contentType
    })

    return {
        headers : httpHeaders,
    }
  }

  public get(api: string , params: any = null){
    console.log("calllll from gggggggget.");
    var apiUrl = this.staticService.getApiUrl()+api;
    return this.httpClient.get(apiUrl, this.prepareHttpOption(params));
  }
  public post(api: string, params: any, paramAsBody: boolean = false){
     // //console.log('api '+api);
     // //console.log('values '+JSON.stringify(values));
      ////console.log('api details '+this.staticService.getApiUrl()+api);
      // //debugger;
      console.log("calllll from pooooooooost.");
    try{
      // this.staticService.showProgressbar();
   //   debugger;
      let body = JSON.parse(JSON.stringify(params));
      var options = paramAsBody == false ? this.prepareHttpOption(params) : this.prepareHttpOptionParamAsBody(params);
      //debugger;
      return this.httpClient.post(this.staticService.getApiUrl()+api, body, options);
        
    }
    catch(ex){
        return null;
    }
  }
  public put(api: string, params: any, paramAsBody: boolean = false){
    // //console.log('api '+api);
    // //console.log('values '+JSON.stringify(values));
     ////console.log('api details '+this.staticService.getApiUrl()+api);
    try{
      // this.staticService.showProgressbar();
      let body = JSON.parse(JSON.stringify(params));
      var options = paramAsBody == false ? this.prepareHttpOption(params) : this.prepareHttpOptionParamAsBody(params);
      return this.httpClient.put(this.staticService.getApiUrl()+api, body, this.prepareHttpOption(params));
        
    }
    catch(ex){
        return null;
    }
  }
  public delete(api: string, params: any){
    // //console.log('api '+api);
    // //console.log('values '+JSON.stringify(values));
     ////console.log('api details '+this.staticService.getApiUrl()+api);
    try{
      // this.staticService.showProgressbar();
      let body = JSON.parse(JSON.stringify(params));
      return this.httpClient.delete(this.staticService.getApiUrl()+api,this.prepareHttpOptionWithBody(params, body));
        
    }
    catch(ex){
        return null;
    }
  }
  public findKey(userName: string, userPassword: string) : any
  {
    console.log("calllll from find  key.");
    var params:string  = "username="+userName+"&password="+userPassword+"&grant_type=password";

    // this.staticService.showProgressbar();
    //console.log('token url');
    //console.log(this.staticService.getTokenUrl());
    return this.httpClient.post(this.staticService.getTokenUrl(), params, this.prepareTokenHttpOption());
  }
}


